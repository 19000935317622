import * as React from 'react';

import Container from '../../../common/container';

import * as s from './styles.module.scss';

const EdTech = () => {
  return (
    <section className={s.edTech}>
      <Container>
        <div className={s.edTech_content}>
          <h3 className={s.edTech_title}>Solutions for every market</h3>
          <p className={s.edTech_text}>
            At Edunomic, we pride ourselves on being a versatile and adaptable
            technology partner. We believe that technology has the power to transform
            any business, regardless of industry or vertical market. That's why we
            work with a wide range of clients, from startups to established enterprises,
            across a diverse set of industries, including healthcare, finance, retail,
            and more.
          </p>
        </div>
      </Container>
    </section>
  );
};

export default EdTech;
