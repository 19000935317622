import * as React from 'react';

import coreImg from '../../../assets/images/core.jpg';

import * as s from './styles.module.scss';

const Core = () => {
  return (
    <section className={s.core}>
      <div className={s.core_content}>
        <div className={s.core_wrapper}>
          <h2 className={s.core_title}>Ready at Every Stage</h2>
          <p className={s.core_text}>
            Whether your project is already underway or you're still ideating,
            we're ready to dive in. From project design to validation, implementation
            to value realization, Edunomic has the talent, services, and training
            strategies to build for success.
          </p>
          <p className={s.core_text}>
            It all starts with identifying your desired outcomes. Meeting deadlines
            on tasks and milestones is only part of the project lifecycle; the true
            measure of success is how well we helped you attain your business outcomes.
          </p>
        </div>
        <img className={s.core_img} src={coreImg} alt="Core" />
      </div>
    </section>
  );
};

export default Core;
