import * as React from 'react';

import Container from '../../../common/container';
import cyberBytes from '../../../assets/images/cyber-bytes.png';
import steamBridge from '../../../assets/images/steam-bridge.png';

import * as s from './styles.module.scss';

const Supporters = () => {
  return (
    <section className={s.supporters}>
      <Container>
        <div className={s.supporters_content}>
          <div className={s.supporters_wrapper}>
            <h3 className={s.supporters_title}>Edunomic Supporters</h3>
            <p className={s.supporters_text}>
              Our Partners mean everything to us, and ensure that
              Edunomic customers are being served responsibly and
              efficiently towards their goals, whether financial or strategic.
            </p>
          </div>
          <div className={s.supporters_sponsors}>
            <img src={cyberBytes} alt="Cyber Bytes Foundation" />
            <img src={steamBridge} alt="SteamBridge" />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Supporters;
