import * as React from 'react';

import Container from '../../../common/container';
import onChainImg from '../../../assets/images/technology-logo.png';

import * as s from './styles.module.scss';

const Hero = () => {
  return (
    <section className={s.hero}>
      <Container className={s.hero_content}>
        <div className={s.hero_wrapper}>
          <h1 className={s.hero_title}>We Build Experiences</h1>
          <p className={s.hero_text}>
            It's not just about rushing a solution to market. Project success means
            redefining the user journey.
          </p>
        </div>
        <div className={s.hero_img}>
          <img src={onChainImg} alt="On Chain" />
        </div>
      </Container>
    </section>
  );
};

export default Hero;
