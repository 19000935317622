import * as React from 'react';

import Container from '../../../common/container';
import WelcomeIcon from '../../../assets/icons/welcome.svg';

import * as s from './styles.module.scss';

const Welcome = () => {
  return (
    <section className={s.welcome}>
      <Container>
        <div className={s.welcome_content}>
          <div className={s.welcome_icon}>
            <WelcomeIcon />
          </div>
          <div className={s.welcome_wrapper}>
            <h2 className={s.welcome_title}>We are Edunomic</h2>
            <p className={s.welcome_description}>
              Let us help you navigate the ever-changing technology landscape.
            </p>
            <p className={s.welcome_text}>
              At Edunomic, we believe that success requires a comprehensive approach to 
              technology. That's why we offer a wide range of services to help you meet
              your business goals, whether you're looking to streamline your operations,
              build a custom solution, or explore the cutting edge of the digital landscape.
            </p>
            <p className={s.welcome_text}>
              Our team of experts is here to guide you every step of the way, from initial
              consultation to implementation and beyond. We'll work with you to understand
              your unique needs and desired outcomes, and then develop a tailored solution
              that fits your budget, timeline, and business goals.
            </p>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Welcome;
