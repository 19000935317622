import * as React from 'react';

import Container from '../../../common/container';
import OurJourneyIcon from '../../../assets/icons/journey.svg';

import * as s from './styles.module.scss';

const OurJourney = () => {
  return (
    <section className={s.ourJourney}>
      <Container>
        <div className={s.ourJourney_content}>
          <div className={s.ourJourney_wrapper}>
            <h5 className={s.ourJourney_title}>We'll bring the technology to you</h5>
            <p className={s.ourJourney_text}>
              Edunomic is everywhere you are, and can work with your unique tech
              stack. Our solution architects can recommend and deploy any environments
              your project requires, or we can use your existing cloud or on-prem systems.
            </p>
          </div>
          <div className={s.ourJourney_icon}>
            <OurJourneyIcon />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default OurJourney;
