import * as React from 'react';

import Container from '../../../common/container';
import cube from '../../../assets/images/cube.png';
import polygon from '../../../assets/images/polygon.png';

import * as s from './styles.module.scss';

const Hero = () => {
  return (
    <section className={s.hero}>
      <Container className={s.hero_container}>
        <div className={s.hero_content}>
          <div className={s.hero_wrapper}>
            <h1 className={s.hero_title}>Real Solutions the Edunomic Way</h1>
            <p className={s.hero_text}>
              We're all in for your success. Let's build something amazing together.
            </p>
          </div>
          <div className={s.hero_cube}>
            <div className={s.hero_polygon}>
              <img src={polygon} alt="Polygon" />
            </div>
            <div className={s.hero_cubeImg}>
              <img src={cube} alt="Cube" />
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Hero;
