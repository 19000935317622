import * as React from 'react';

import Container from '../../../common/container';
import CollegeIcon from '../../../assets/icons/college.svg';
import KnowledgeIcon from '../../../assets/icons/knowledge.svg';
import HostingIcon from '../../../assets/icons/hosting.svg';
import CoinIcon from '../../../assets/icons/coin.svg';

import * as s from './styles.module.scss';

const TOOLS = [
  { icon: <CollegeIcon />, description: 'Training & Enablement' },
  { icon: <KnowledgeIcon />, description: 'Product Development' },
  { icon: <HostingIcon />, description: 'Solution Architecture' },
  { icon: <CoinIcon />, description: 'Value Realization' },
];

const EcosystemTools = () => {
  return (
    <section className={s.ecosystemTools}>
      <Container>
        <div className={s.ecosystemTools_content}>
          <h3 className={s.ecosystemTools_title}>Expert and Advisory Services</h3>
          <div className={s.ecosystemTools_list}>
            {TOOLS?.map((tool) => (
              <div className={s.ecosystemTools_item} key={tool.description}>
                <div className={s.ecosystemTools_icon}>{tool.icon}</div>
                <p className={s.ecosystemTools_description}>
                  {tool.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default EcosystemTools;
